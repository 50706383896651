import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { VenueResponse } from 'src/features/venue';
import { venueIdGETPath } from 'src/features/venue/api/paths';
import { httpClient } from 'src/lib/axios';

const getVenue = async (): Promise<VenueResponse> => {
  const {
    data: { payload },
  } = await httpClient.get(`${venueIdGETPath()}`);

  return payload as VenueResponse;
};

export const useGetVenue = (options?: UseQueryOptions<VenueResponse>) => {
  return useQuery<VenueResponse>({
    queryKey: ['venueMetadata'],
    queryFn: () => getVenue(),
    keepPreviousData: true,
    ...options,
  });
};
