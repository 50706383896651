import loadable from '@loadable/component';
import { Navigate, Outlet } from 'react-router-dom';

// Default level routes
const UserRoutes = loadable(() => import('src/features/users/routes'), {
  resolveComponent: components => components.UserRoutes,
});
const LocationRoutes = loadable(() => import('src/features/locations/routes'), {
  resolveComponent: components => components.LocationRoutes,
});
const TagRoutes = loadable(() => import('src/features/tags/routes'), {
  resolveComponent: components => components.TagRoutes,
});
const MediaRoutes = loadable(() => import('src/features/mediaLibrary/routes'), {
  resolveComponent: components => components.MediaRoutes,
});
const PlaylistRoutes = loadable(() => import('src/features/playlists/routes'), {
  resolveComponent: components => components.PlaylistRoutes,
});
const AnalyticsRoutes = loadable(() => import('src/features/analytics/routes'), {
  resolveComponent: components => components.AnalyticsRoutes,
});
const App = () => {
  return <Outlet />;
};

export const protectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: 'users/*', element: <UserRoutes /> },
      { path: 'locations/*', element: <LocationRoutes /> },
      { path: 'tags/*', element: <TagRoutes /> },
      { path: 'library/*', element: <MediaRoutes /> },
      { path: 'playlist/*', element: <PlaylistRoutes /> },
      { path: 'analytics/*', element: <AnalyticsRoutes /> },
      { path: '/', element: <Navigate to="playlist" /> },
      { path: '/*', element: <Navigate to="playlist" /> },
    ],
  },
];
