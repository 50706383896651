import { ReactElement, useState } from 'react';

import { useVenueContext } from 'src/contexts/venueContext';
import { useGetVenue } from 'src/features/venue/api/getVenue';

interface VenueProviderProps {
  children: ReactElement[] | ReactElement;
}

export const VenueProvider = ({ children }: VenueProviderProps): JSX.Element | null => {
  const [venueIsDefined, setVenueIsDefined] = useState<boolean>(false);
  const { setActiveVenue } = useVenueContext();

  useGetVenue({
    onSuccess: data => {
      setActiveVenue({
        id: `${data.id}`,
        name: `${data.name}`,
        mediaOrientation: `${data.mediaOrientation}`,
        venueType: `${data.venueType}`,
      });
      setVenueIsDefined(true);
    },
  });

  return venueIsDefined ? <>{children}</> : <></>;
};
