import isEqual from 'lodash/isEqual';
import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
interface LocationFilterContext {
  tagIds?: number[];
}

const noFiltersApplied: LocationFilterContext = {
  tagIds: [],
};

const LocationFilterContext = createContext({
  locationFilterValues: {} as LocationFilterContext,
  setLocationFilterValues: {} as Dispatch<SetStateAction<LocationFilterContext>>,
});

export const useLocationFilterContext = () => {
  const { locationFilterValues, setLocationFilterValues } = useContext(LocationFilterContext);

  const [contextValue, setContextValue] = useState<LocationFilterContext>({
    tagIds: [],
  });

  const hasFiltersApplied = useMemo(() => {
    return !isEqual(locationFilterValues, noFiltersApplied);
  }, [locationFilterValues]);

  const handleClearFilters = () => {
    setLocationFilterValues(noFiltersApplied);
  };

  const locationDefaultValueForContextProvider = {
    locationFilterValues: contextValue,
    setLocationFilterValues: setContextValue,
  };

  return {
    locationDefaultValueForContextProvider,
    locationFilterValues,
    setLocationFilterValues,
    handleClearFilters,
    hasFiltersApplied,
    LocationFilterContext,
  };
};
