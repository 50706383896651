import { HeadCell } from 'src/types';

export const PlaylistTableHeadCells: readonly HeadCell[] = [
  {
    id: 'draggable_icon',
    align: 'left',
    disablePadding: false,
    label: '',
    sorting: {
      isSortable: false,
    },
  },
  {
    id: 'thumbnail',
    align: 'left',
    disablePadding: false,
    label: 'Thumbnail',
    sorting: {
      isSortable: false,
    },
  },
  {
    id: 'name',
    align: 'left',
    disablePadding: false,
    label: 'Name',
    sorting: {
      isSortable: false,
    },
  },
  {
    id: 'actions',
    align: 'left',
    disablePadding: false,
    label: 'Actions',
    sorting: {
      isSortable: false,
    },
  },
  {
    id: 'status',
    align: 'left',
    disablePadding: false,
    label: 'Status',
    sorting: {
      isSortable: false,
    },
  },
  {
    id: 'locations',
    align: 'left',
    disablePadding: false,
    label: 'Locations',
    sorting: {
      isSortable: false,
    },
  },
  {
    id: 'start_date',
    align: 'left',
    disablePadding: false,
    label: 'Start Date',
    sorting: {
      isSortable: false,
    },
  },
  {
    id: 'end_date',
    align: 'left',
    disablePadding: false,
    label: 'End Date',
    sorting: {
      isSortable: false,
    },
  },
];

export const PlaylistLocationTableHeadCells: readonly HeadCell[] = [
  {
    id: 'name',
    align: 'left',
    disablePadding: true,
    label: 'Name',
    sorting: {
      isSortable: true,
      isDefaultSort: true,
    },
  },
  {
    id: 'tags',
    align: 'left',
    disablePadding: false,
    label: 'Tags',
    sorting: {
      isSortable: false,
    },
  },
];

export const PlaylistTagTableHeadCells: readonly HeadCell[] = [
  {
    id: 'name',
    align: 'left',
    disablePadding: true,
    label: 'Name',
    sorting: {
      isSortable: true,
      isDefaultSort: true,
    },
  },
  {
    id: 'type',
    align: 'left',
    disablePadding: false,
    label: 'Type',
    sorting: {
      isSortable: false,
    },
  },
];

export const onSuccessPlaylistItemNavigateTo = () => '/playlist';

export const playlistItemDetailsNavigateTo = (playlistItemId: string | number) => `/playlist/${playlistItemId}`;
