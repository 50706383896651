import { ReactElement } from 'react';

import { useLocationFilterContext } from 'src/features/locations/contexts/locationFilterContext';
import { useMediaFilterContext } from 'src/features/mediaLibrary/contexts/mediaFilterContext';
import { usePlaylistFilterContext } from 'src/features/playlists/contexts/playlistFilterContext';
import { useTagFilterContext } from 'src/features/tags/contexts/tagFilterContext';
import { useUserFilterContext } from 'src/features/users/contexts/userFilterContext';

interface FiltersProviderProps {
  children: ReactElement[] | ReactElement;
}

export const FiltersProvider = ({ children }: FiltersProviderProps) => {
  const { TagFilterContext, tagDefaultValueForContextProvider } = useTagFilterContext();
  const { LocationFilterContext, locationDefaultValueForContextProvider } = useLocationFilterContext();
  const { MediaFilterContext, mediaDefaultValueForContextProvider } = useMediaFilterContext();
  const { UserFilterContext, userDefaultValueForContextProvider } = useUserFilterContext();
  const { PlaylistFilterContext, playlistDefaultValueForContextProvider } = usePlaylistFilterContext();

  return (
    <TagFilterContext.Provider value={tagDefaultValueForContextProvider}>
      <LocationFilterContext.Provider value={locationDefaultValueForContextProvider}>
        <MediaFilterContext.Provider value={mediaDefaultValueForContextProvider}>
          <UserFilterContext.Provider value={userDefaultValueForContextProvider}>
            <PlaylistFilterContext.Provider value={playlistDefaultValueForContextProvider}>
              {children}
            </PlaylistFilterContext.Provider>
          </UserFilterContext.Provider>
        </MediaFilterContext.Provider>
      </LocationFilterContext.Provider>
    </TagFilterContext.Provider>
  );
};
