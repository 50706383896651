import { ReactElement } from 'react';

import { usePageTitleContext } from 'src/contexts/pageTitleContext';
import { useVenueContext } from 'src/contexts/venueContext';

interface ContextProviderProps {
  children: ReactElement[] | ReactElement;
}

export const ContextProvider = ({ children }: ContextProviderProps) => {
  const { venueDefaultValueForContextProvider, VenueContext } = useVenueContext();
  const { pageTitleDefaultValueForContextProvider, PageTitleContext } = usePageTitleContext();

  return (
    <PageTitleContext.Provider value={pageTitleDefaultValueForContextProvider}>
      <VenueContext.Provider value={venueDefaultValueForContextProvider}>{children}</VenueContext.Provider>
    </PageTitleContext.Provider>
  );
};
