import isEqual from 'lodash/isEqual';
import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';

import { DAYS_OF_THE_WEEK } from 'src/config';

interface PlaylistFilterContext {
  locationIds?: number[];
  mediaTagIds?: number[];
  locationTagIds?: number[];
  startDate?: string;
  endDate?: string;
  dailyStartTime?: string;
  dailyEndTime?: string;
  daysOfWeek: (keyof typeof DAYS_OF_THE_WEEK)[];
}

const PlaylistFilterContext = createContext({
  playlistFilterValues: {} as PlaylistFilterContext,
  setPlaylistFilterValues: {} as Dispatch<SetStateAction<PlaylistFilterContext>>,
});

const noFiltersApplied: PlaylistFilterContext = {
  locationIds: [],
  mediaTagIds: [],
  locationTagIds: [],
  startDate: undefined,
  endDate: undefined,
  dailyStartTime: undefined,
  dailyEndTime: undefined,
  daysOfWeek: [],
};

export const usePlaylistFilterContext = () => {
  const { playlistFilterValues, setPlaylistFilterValues } = useContext(PlaylistFilterContext);

  const [contextValue, setContextValue] = useState<PlaylistFilterContext>({
    locationIds: [],
    mediaTagIds: [],
    locationTagIds: [],
    startDate: undefined,
    endDate: undefined,
    dailyStartTime: undefined,
    dailyEndTime: undefined,
    daysOfWeek: [],
  });

  const hasFiltersApplied = useMemo(() => {
    return !isEqual(playlistFilterValues, noFiltersApplied);
  }, [playlistFilterValues]);

  const handleClearFilters = () => {
    setPlaylistFilterValues(noFiltersApplied);
  };

  const playlistDefaultValueForContextProvider = {
    playlistFilterValues: contextValue,
    setPlaylistFilterValues: setContextValue,
  };

  return {
    playlistDefaultValueForContextProvider,
    playlistFilterValues,
    setPlaylistFilterValues,
    handleClearFilters,
    hasFiltersApplied,
    PlaylistFilterContext,
  };
};
