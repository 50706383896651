import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CollectionsIcon from '@mui/icons-material/Collections';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PlaceIcon from '@mui/icons-material/Place';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const listOfBasicRoutes = ['/playlist', '/library', '/tags', '/locations', '/users'];

export const NavList = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <>
      {!listOfBasicRoutes.includes(pathname) ? (
        <List sx={{ backgroundColor: 'primary.main' }} component="nav">
          <ListItemButton onClick={() => navigate(-1)}>
            <ListItemIcon sx={{ color: 'white' }}>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary="Back" />
          </ListItemButton>
        </List>
      ) : null}
      <List sx={{ backgroundColor: 'primary.main' }} component="nav">
        <ListItemButton onClick={() => navigate('/playlist')}>
          <ListItemIcon sx={{ color: 'white' }}>
            <PlaylistAddIcon />
          </ListItemIcon>
          <ListItemText primary="Playlist" />
        </ListItemButton>
        <Divider />
        <ListItemButton onClick={() => navigate('/library')}>
          <ListItemIcon sx={{ color: 'white' }}>
            <CollectionsIcon />
          </ListItemIcon>
          <ListItemText primary="Library" />
        </ListItemButton>
        <Divider />
        <ListItemButton onClick={() => navigate('/tags')}>
          <ListItemIcon sx={{ color: 'white' }}>
            <LocalOfferIcon />
          </ListItemIcon>
          <ListItemText primary="Tags" />
        </ListItemButton>
        <Divider />
        <ListItemButton onClick={() => navigate('/locations')}>
          <ListItemIcon sx={{ color: 'white' }}>
            <PlaceIcon />
          </ListItemIcon>
          <ListItemText primary="Locations" />
        </ListItemButton>
        <Divider />
        <ListItemButton onClick={() => navigate('/users')}>
          <ListItemIcon sx={{ color: 'white' }}>
            <PersonOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItemButton>
        <Divider />
        <ListItemButton onClick={() => navigate('/analytics')}>
          <ListItemIcon sx={{ color: 'white' }}>
            <StackedLineChartIcon />
          </ListItemIcon>
          <ListItemText primary="Analytics" />
        </ListItemButton>
      </List>
    </>
  );
};
