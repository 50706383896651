import { useAuth0 } from '@auth0/auth0-react';
import { Box, CssBaseline, Divider, Drawer, Grid, Toolbar, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import logoMedium from 'src/assets/logoMedium.png';
import { NavBar, NavList } from 'src/components/Layout';
import { onSuccessPlaylistItemNavigateTo } from 'src/features/playlists/constants';

const PERMANENT_DRAWER = {
  WIDTH: 180,
};

interface DrawerProps {
  children: ReactElement | ReactElement[];
}

export const PermanentDrawer = ({ children }: DrawerProps) => {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  return isAuthenticated ? (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <NavBar drawerWidth={PERMANENT_DRAWER.WIDTH} />
      <Drawer
        PaperProps={{
          sx: {
            width: PERMANENT_DRAWER.WIDTH,
            boxSizing: 'border-box',
            backgroundColor: 'primary.main',
            color: 'white',
          },
        }}
        sx={{
          width: PERMANENT_DRAWER.WIDTH,
          flexShrink: 0,
        }}
        variant="permanent"
        anchor="left"
      >
        <Grid
          sx={{ pt: 2, cursor: 'pointer' }}
          container
          justifyContent="center"
          alignItems="center"
          onClick={() => navigate(`${onSuccessPlaylistItemNavigateTo()}`)}
        >
          <Grid item>
            <img src={logoMedium} alt="logo" width={42.4} height={27} />
          </Grid>
          <Grid item>
            <Typography>
              <strong>VENUE</strong> PANEL
            </Typography>
          </Grid>
        </Grid>
        <Toolbar />
        <Divider />
        <NavList />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  ) : null;
};
