import isEqual from 'lodash/isEqual';
import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
interface MediaFilterContext {
  tagIds?: number[];
  types?: string[];
}

const noFiltersApplied: MediaFilterContext = {
  tagIds: [],
  types: [],
};

const MediaFilterContext = createContext({
  mediaFilterValues: {} as MediaFilterContext,
  setMediaFilterValues: {} as Dispatch<SetStateAction<MediaFilterContext>>,
});

export const useMediaFilterContext = () => {
  const { mediaFilterValues, setMediaFilterValues } = useContext(MediaFilterContext);

  const [contextValue, setContextValue] = useState<MediaFilterContext>({
    tagIds: [],
    types: [],
  });

  const hasFiltersApplied = useMemo(() => {
    return !isEqual(mediaFilterValues, noFiltersApplied);
  }, [mediaFilterValues]);

  const handleClearFilters = () => {
    setMediaFilterValues(noFiltersApplied);
  };

  const mediaDefaultValueForContextProvider = {
    mediaFilterValues: contextValue,
    setMediaFilterValues: setContextValue,
  };

  return {
    mediaDefaultValueForContextProvider,
    mediaFilterValues,
    setMediaFilterValues,
    handleClearFilters,
    hasFiltersApplied,
    MediaFilterContext,
  };
};
