import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

interface VenueContext {
  id: string;
  name?: string;
  venueType: string;
  mediaOrientation: string;
}

const VenueContext = createContext({
  activeVenue: {} as VenueContext,
  setActiveVenue: {} as Dispatch<SetStateAction<VenueContext>>,
});

export const useVenueContext = () => {
  const { activeVenue, setActiveVenue } = useContext(VenueContext);

  const [contextValue, setContextValue] = useState<VenueContext>({
    id: '',
    name: '',
    venueType: 'SCREEN',
    mediaOrientation: 'PORTRAIT',
  });

  const venueDefaultValueForContextProvider = {
    activeVenue: contextValue,
    setActiveVenue: setContextValue,
  };

  return {
    venueDefaultValueForContextProvider,
    activeVenue,
    setActiveVenue,
    VenueContext,
  };
};
