import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ReactElement } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { MainLayout } from 'src/components/Layout';
import { GenericErrorPage } from 'src/features/miscellaneous';
import { queryClient } from 'src/lib/react-query';
import { AccessTokenProvider } from 'src/providers/accessToken';
import { AuthProvider } from 'src/providers/auth';
import { ContextProvider } from 'src/providers/context';
import { FiltersProvider } from 'src/providers/filters';
import { InterceptorProvider } from 'src/providers/interceptors';
import { SecureProvider } from 'src/providers/secure';
import { theme } from 'src/providers/theme';
import { ToastProvider } from 'src/providers/toast';
import { VenueProvider } from 'src/providers/venue';

type AppProviderProps = {
  children: ReactElement[] | ReactElement;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <ErrorBoundary FallbackComponent={GenericErrorPage}>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <HelmetProvider>
            <QueryClientProvider client={queryClient}>
              {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
              <BrowserRouter>
                <SecureProvider>
                  <AccessTokenProvider>
                    <InterceptorProvider>
                      <ContextProvider>
                        <VenueProvider>
                          <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <ToastProvider />
                            <FiltersProvider>
                              <MainLayout>{children}</MainLayout>
                            </FiltersProvider>
                          </LocalizationProvider>
                        </VenueProvider>
                      </ContextProvider>
                    </InterceptorProvider>
                  </AccessTokenProvider>
                </SecureProvider>
              </BrowserRouter>
            </QueryClientProvider>
          </HelmetProvider>
        </ThemeProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
};
